import React, { useState, useEffect } from "react";
import Slider from "react-slick";
// import slowmo from "../images/PetPlants.mp4";
// import slowmo from "https://s3.ap-south-1.amazonaws.com/images.petplants.in/videos/PetPlants.mp4";
import { Link } from "react-router-dom";

const MainSlider = () => {
  // let slowmo = require("../images/PetPlants.mp4");
  var slowmo = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/videos/PetPlants.mp4";
  const [isMobile, setIsMobile] = useState(false);
  // let mobileBanner = require("../images/diwali_mobile_banner.png");
  useEffect(() => {
    handleWindowSizeChange();
    console.log("navigator.userAgent: ", navigator.userAgent);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    console.log("window.innerWidth: ", window.innerWidth);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    console.log("isMobile: ", isMobile);
  }, [isMobile]);

  // const isMobile = width <= 768;

  var hsettings = {
    dots: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplaySpeed: 7000,
    pauseOnHover: true,
  };

  return (
    <>
      <div className="container-fluid" id="home">
        <div className="row p-0">
          <div className="col-12 p-0">
            <Slider {...hsettings}>
              {/* {isMobile ? (
                <div className="carousel-mainslide bgGreen flex-center">
                  <div
                    class="carousel-mainslide bgimg11-mobile bgGreen flex-center"
                 
                  ></div>
                </div>
              ) : (
                <div
                className="carousel-mainslide bgimg11-desktop bgGreen  flex-center"
                ></div>
              )} */}

              {/* <div class="carousel-mainslide bgimg1 flex-center bgGreen " data-bs-toggle="modal"
              data-bs-target="#myModal">
              </div>  */}

              {/* <div class="carousel-mainslide bgimg1 flex-center" data-bs-toggle="modal"
              data-bs-target="#myModal">
          <h5>Start</h5>
          <h1 className="slider_main_text margin-top-27">YOUR <span className="slider_highlight">ECO</span> JOURNEY</h1>
          <p className="margin-top-27">Add a Pet Plant to your family</p>
          <button
              className="buy_button bgOrange my-4 px-5 d-none d-md-block"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              // ref={(input) => {
              //   buyNowBtn = input;
              // }}
              // onClick={() => handleOrderState("InCart")}
            >
              BUY NOW
            </button>
        </div>
         */}

              {/* {isMobile ? (
                <Link className="no-decoration" to={`/plant/jade-plant-collection-6`} state={{ productLinkData: {type: "collection"} }}>
              <div className="carousel-mainslide bgGreen flex-center">
                <div
                  class="carousel-mainslide bgimg6-mobile bgGreen flex-center"
                
                ></div>
              </div>
              </Link>
              ) : (
                null
              )}

              {isMobile ? (
                <Link className="no-decoration" to={`/plant/jade-plant-collection-12`} state={{ productLinkData: {type: "collection"} }}>
              <div className="carousel-mainslide bgGreen flex-center">
                <div
                  class="carousel-mainslide bgimg12-mobile bgGreen flex-center"
                
                ></div>
              </div>
              </Link>
              ) : (
                null
              )} */}

              {/* {isMobile ? (
                <Link className="no-decoration" to={`/plant/jade-plant`} state={{ productLinkData: {type: "solo"} }}>
              <div className="carousel-mainslide bgGreen flex-center">
                <div
                  class="carousel-mainslide bgimg-new-year bgGreen flex-center"
                
                ></div>
              </div>
              </Link>
              ) : (
                null
              )} */}

              {/* <div className="carousel-mainslide bgimgcomingsoon flex-center"></div> */}

              <Link className="no-decoration" to={`/plant/spider-plant`} state={{ productLinkData: {type: "solo"} }}>
              <div className="carousel-mainslide bgimg1 flex-center">
                <h5>Start</h5>
                <h1 className="slider_main_text margin-top-27">
                  YOUR <span className="slider_highlight">ECO</span> JOURNEY
                </h1>
                <p className="margin-top-27">Add a Pet Plant to your family</p>
                {/* <button
              className="buy_button bgOrange my-4 px-5 d-none d-md-block"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
            >
              BUY NOW
            </button> */}
              </div>
              </ Link>

              <Link className="no-decoration" to={`/plant/jade-plant`} state={{ productLinkData: {type: "solo"} }}>
              <div className="carousel-mainslide bgimg2 flex-center">
                <h5>Benefits of your</h5>
                <h1 className="slider_main_text margin-top-27">
                  PET <span className="slider_highlight">PLANT</span>
                </h1>
                <p className="margin-top-27">
                  They can boost productivity, improves mood, regulates humidity
                  and looks beautiful
                </p>
              </div>
              </Link>

              {/* <div class="carousel-mainslide bgimg3 flex-center">
                <h5>30 DAYS</h5>
                <h1 className="slider_main_text margin-top-27">
                  <span>
                    {" "}
                    <span className="slider_highlight">
                      MONEY BACK 
                    </span>
                  </span>
                </h1>
                <p className="margin-top-27">GUARANTEE*</p>
              </div> */}

              {/* Video Slide */}
              <div className="carousel-mainslide bgvideo1 flex-center">
                <video className="sliderVideo" autoPlay muted loop>
                  <source src={slowmo} type="video/mp4" />
                </video>
                <h5 className="z-index-9 txtWhite">Watch Your</h5>
                <h1 className="slider_main_text margin-top-27 z-index-9 txtWhite">
                  <span>
                    {" "}
                    <span className="slider_highlight ">PET PLANT</span>
                  </span>
                </h1>
                <p className="margin-top-27 z-index-9 txtWhite">Grow</p>
              </div>

              {/* <div className="carousel-mainslide bgimg4 flex-center">
                <h5>An Indian uses</h5>
                <h1 className="slider_main_text margin-top-27">
                  11 <span className="slider_highlight">KGS</span>
                </h1>
                <p className="margin-top-27">of plastic every year</p>
              </div>

              <div class="carousel-mainslide bgimg5 flex-center">
                <h5>Everyday</h5>
                <h1 className="slider_main_text margin-top-27">
                  26,000
                  <span>
                    {" "}
                    <span className="slider_highlight">TONNES</span>
                  </span>
                </h1>
                <p className="margin-top-27">
                  of plastic waste is generated in India
                </p>
              </div> */}

              {/* <Link className="no-decoration" to={`/plant/money-plant`}>
                <div class="carousel-mainslide bgimg5 flex-center">
                  <h5>Featured Plant</h5>
                  <h1 className="slider_main_text margin-top-27">
                    {" "}
                    <span>
                      {" "}
                      <span className="slider_highlight">MONEY </span>
                    </span>{" "}
                    PLANT
                  </h1>
                  <p className="margin-top-27">Loved by All</p>
                </div>
              </Link> */}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSlider;
