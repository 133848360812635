import React from 'react'
import NavbarFiller from './NavbarFiller';

const Contact = () => {
  return (
    <>
    <div className="container-fluid" id="contact">
    <NavbarFiller />
    <div className='row'>
      <div className='col-12 text-center p-4 m-0'>
      <h3>CONTACT US</h3>
      
      <h5 className='mt-5'>
      <i className="fa fa-mobile txtBlack txtS36" ></i><br />
    Mobile
    </h5>
    <p className='mt-3'>
    8097749932
    </p>

    <h5 className='mt-5'>
      <i className="fa fa-envelope txtBlack txtS36" ></i><br />
    Email
    </h5>
    <p className='mt-3'>
    petplants.eco@gmail.com
    </p>


    <h5 className='mt-5'>
    <i className="fa fa-map-marker txtBlack txtS36" ></i><br />
    Address
    </h5>
    <p className='mt-3'>
    Thane, 400612
    </p>

      </div>
    </div>
    </div>
    </>
  )
}

export default Contact