import React, { useState, useEffect } from 'react'
import { isMobile} from "react-device-detect";

let deferredPrompt;

const Install = () => {
    const [installable, setInstallable] = useState(false);

  // Initialisation
  useEffect(() => {

    // Check if browser supports Installing
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      console.log("deferredPrompt: ", deferredPrompt);
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });

    window.addEventListener("appinstalled", () => {
      // Log install to analytics
      console.log("INSTALL: Success");
    });
    console.log("deferredPrompt: ", deferredPrompt);
  }, []);

  function handleInstallClick(e) {
    // Hide the app provided install promotion
    setInstallable(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  }

  return (
    <>
        {installable && isMobile && (
            <div className="bgOrange p-4 text-center txtWhite " onClick={handleInstallClick}>
                <h3><i
                    class="fa fa-download margin-right"
                    aria-hidden="true"
                ></i>
                Install App
                </h3>
                {/* <button
                type="button"
                className="btn btn-success m-5"
                
                >
                <i
                    class="fa fa-download margin-right"
                    aria-hidden="true"
                ></i>
                Install
                </button> */}
            </div>
        )}
    </>
  )
}

export default Install