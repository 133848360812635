import React from "react";
import { Link } from "react-router-dom";

const LinksComponent = () => {
  return (
    <>
      <div className="container-fluid p-3 p-md-5">
        <div className="row">
        <div className="col-12 col-md-6">
        {/* <hr className="d-md-none"></hr> */}
            <h5>Links</h5>
            <h6>
              <Link
                className="no-decoration text-body"
                to={`/`}
              >
                Home
              </Link>
            </h6>

            {/* <h6>
              <Link
                className="no-decoration text-body"
                to={`/receipt`}
              >
                Order Receipt
              </Link>
            </h6> */}

            {/* <h6>
              <Link
                className="no-decoration text-body"
                to={`/termsandconditions`}
              >
                All Plants
              </Link>
            </h6> */}

            {/* <h6>
              <Link
                className="no-decoration text-body"
                to={`/termsandconditions`}
              >
                FAQS
              </Link>
            </h6> */}

            <h6>
              <Link
                className="no-decoration text-body"
                to={`/blogs`}
              >
                Blogs
              </Link>
            </h6>

            <h6>
              <Link
                className="no-decoration text-body"
                to={`/plants`}
              >
                All Plants
              </Link>
            </h6>


            
            <h6>
              <Link
                className="no-decoration text-body"
                to={`/contactus`}
              >
                Contact Us
              </Link>
            </h6>

          </div>
            <hr className="d-md-none"></hr>
          <div className="col-12 col-md-6 mt-2 mt-md-0">
            <h5>Policies</h5>
            <h6>
              <Link
                className="no-decoration text-body"
                to={`/termsandconditions`}
              >
                Terms & Conditions
              </Link>
            </h6>

            <h6>
              <Link
                className="no-decoration text-body"
                to={`/privacypolicy`}
              >
                Privacy Policy
              </Link>
            </h6>

            <h6>
              <Link
                className="no-decoration text-body"
                to={`/refundpolicy`}
              >
                Return and Refund Policy
              </Link>
            </h6>

            <h6>
              <Link
                className="no-decoration text-body"
                to={`/shippingpolicy`}
              >
                Shipping Policy
              </Link>
            </h6>

          </div>
        </div>
      </div>
    </>
  );
};

export default LinksComponent;
