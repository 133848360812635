import React from 'react';
import { Link } from "react-router-dom";

const BottomBuyNow = ({plantDetails}) => {
  const { productId, potShape, potColor, product } = plantDetails;
  function shareClickHandler() {
    if (navigator.share) {
      navigator.share({
        // title: 'Pet Plants',
        // url: 'https://petplants.in',
        // text: "Sharing plant details.",

        title: `${product.productDetails.name.value} - Pet Plants`,
        url: `https://petplants.in/plant/${productId}`,
        text: `Sharing ${product.productDetails.name.value} details.`,
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    }
  }

  return (
    <div className='container-fluid fixed-bottom'>
    <div className='row d-md-none text-center'>
      {
        navigator.share && 
        <div className='col-6 p-3 bgGreen txtWhite' onClick={() => shareClickHandler()}>
        {/* <a class="txtBlack555 no-decoration txtWhite" href="whatsapp://send?text=www.mnikaah.com - Muslim Matrimonial Website in India" data-action="share/whatsapp/share"> */}

        {/* <i class="fa fa-whatsapp" aria-hidden="true"></i>  */}
        <i class="fa fa-share margin-right " aria-hidden="true"></i>
        Share
        {/* </a> */}
        {/* Share */}
        </div>
      }

      {/* <div className='col-6 half-width bgOrange txtWhite p-3 bottom-buy-now'
      data-bs-toggle="modal"
      data-bs-target="#myModal"> */}
        <Link className={`bgOrange txtWhite p-3 bottom-buy-now no-decoration ${ navigator.share ? 'col-6 half-width' : 'col-12 full-width'}`} to={`/checkout`} 
        state={{ productId, potShape, potColor, product }}>
                {/* <button
                  class="btn btn-block full-width  bgOrange txtWhite "
                  type="button"
                > */}
        <div className=''>BUY NOW</div>
                {/* </button> */}
              </Link>
        
      {/* </div> */}
    </div>
    </div>
  )
}

export default BottomBuyNow