import React, {useState} from "react";
import { isMobile } from "react-device-detect";
import Slider from "react-slick";
import ProductCarouselItem from "./ProductCarouselItem";

const ProductCarousel = ({title}) => {

  let moneyplant = require("../images/products/MP_Round_White_Top_1.jpeg");
  let jadeplant = require("../images/products/Jade_Round_White_Top_1.jpeg");

  const [productData, setProductData] = useState(
    [
      {
        productName: "Jade Plant (12 Pcs)",
        productId: "1003",
        productPrice: "2368",
        productPriceBeforeDiscount: "3588",
        productDiscountPercentage: "33",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_Purple_Front_1.jpg",
        imageURL: 'jade_collection_1.jpeg',
        linkName: "jade-plant-collection-12",
        type: "collection"
      },
      {
        productName: "Jade Plant (6 Pcs)",
        productId: "1004",
        productPrice: "1185",
        productPriceBeforeDiscount: "1794",
        productDiscountPercentage: "33",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_Purple_Front_1.jpg",
        imageURL: 'jade_collection_1.jpeg',
        linkName: "jade-plant-collection-6",
        type: "collection"
      },
      {
        productName: "Snake Plant",
        productId: "1005",
        productPrice: "299",
        productPriceBeforeDiscount: "399",
        productDiscountPercentage: "25",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg",
        imageURL: 'Snake_Square_White_Front_1.jpeg',
        linkName: "snake-plant",
        type: "solo"
      },
      {
        productName: "Jade Plant",
        productId: "1002",
        productPrice: "195",
        productPriceBeforeDiscount: "299",
        productDiscountPercentage: "35",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_White_Top_1.jpeg",
        imageURL: 'Jade_Round_White_Top_1.jpeg',
        linkName: "jade-plant",
        type: "solo"
      },
      {
        productName: "Spider Plant",
        productId: "1006",
        productPrice: "195",
        productPriceBeforeDiscount: "299",
        productDiscountPercentage: "35",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg",
        imageURL: 'Spider_Square_White_Top_1.jpeg',
        linkName: "spider-plant",
        type: "solo"
      },
      {
        productName: "Money Plant",
        productId: "1001",
        productPrice: "195",
        productPriceBeforeDiscount: "299",
        productDiscountPercentage: "35",
        // imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg",
        imageURL: 'MP_Round_White_Top_1.jpeg',
        linkName: "money-plant",
        type: "solo"
      },            
    ]
  );
  

  var settings = {
    dots: true,
    infinite: productData.length > 3,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: productData.length > 3,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='container-fluid'>
      <div className=''>

      <div>
        <h3 
        className={`m-2 mt-3  ${
          isMobile ? "text-center" : ""
        }`}> {title}</h3>
        <div className="px-2">
        <Slider {...settings}>
        {/* <ProductCarouselItem data={productData[0]} key={productData[0].linkName}/>
        <ProductCarouselItem data={productData[1]} key={productData[1].linkName}/> */}
          {
            productData.map((item) => {
              return (<ProductCarouselItem data={item} key={item.productId}/>)
            })
          }

        </Slider>
      </div>
      </div>
      </div>
    </div>
  )
}

export default ProductCarousel