var productList = [
  {
    "product": "jade-plant-collection-12",
    "jade-plant-collection-12": {
        productData: {
          productName:  'Jade Plant (12 Pcs Collection)',
          productId:  '1003',
          productPrice: "2368",
          productPriceBeforeDiscount: "3588",
          productDiscountPercentage: "33",
          linkName: "jade-plant-collection-12",
          imageURL: "https://petplants.in/static/media/jade_collection_1.75636ed5.jpeg",
          type: "collection"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Jade Plant (12 Pcs Collection)" },
          type: { label: "Type", value:  'Succulent ' },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  'Indoors / Partial Outdoors' },
      
          plantHeight: { label: "Plant Height", value:  "5-10 inch" },
          plantWidth: { label: "Plant Width", value:  "4-8 inch" },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "12" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Shaded, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
  },
  {
    "product": "jade-plant-collection-6",
    "jade-plant-collection-6": {
        productData: {
          productName:  'Jade Plant (6 Pcs Collection)',
          productId:  '1004',
          productPrice: "1185",
          productPriceBeforeDiscount: "1794",
          productDiscountPercentage: "33",
          linkName: "jade-plant-collection-6",
          imageURL: "https://petplants.in/static/media/jade_collection_1.75636ed5.jpeg",
          type: "collection"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Jade Plant (6 Pcs Collection)" },
          type: { label: "Type", value:  'Succulent ' },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  'Indoors / Partial Outdoors' },
      
          plantHeight: { label: "Plant Height", value:  "5-10 inch" },
          plantWidth: { label: "Plant Width", value:  "4-8 inch" },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "6" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Shaded, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
},
{
  "product": "snake-plant",
  "snake-plant":{
      productData: {
        productName:  "Snake Plant",
        productId:  "1005",
        productPrice: "299",
        productPriceBeforeDiscount: "399",
        productDiscountPercentage: "25",
        linkName: "snake-plant",
        imageURL: "https://petplants.in/static/media/Snake_Square_White_Front_1.bffbdb4d.jpeg",
        type: "solo"
      },
      productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
      productDetails: {
        name: { label: "Name", value:  "Snake Plant"  },
        type: { label: "Type", value:  "Sansevieria" },
        // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
        location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
    
        plantHeight: { label: "Plant Height", value:  '8-15 inch' },
        plantWidth: { label: "Plant Width", value:  '2-5 inch' },
    
        // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
        // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
        planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
        // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        containerType: { label: "Container Type", value: "Plastic" },
      },
      manufacturerDetails: {
        // name: {label: "Manufacturer", value: "Pet Plants"},
        // type: {label: "Country of Origin", value: "India"},
        quantity: { label: "Net Quantity", value: "1" },
        // boxHeight: {label: "Plant Weight", value: "250 Grams"},
        // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
        replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
        sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
        watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
        delivery: { label: "Delivery", value: "2-6 working days" },
        // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
        // boxBreadth:{label: "Box Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        // containerType: {label: "Container Type", value: "Pet Bottle"},
      }
    }
},
{ 
        "product": "jade-plant",
        "jade-plant" : {
            productData: {
              productName: 'Jade Plant',
              productId: '1002',
              productPrice: "195",
              productPriceBeforeDiscount: "299",
              productDiscountPercentage: "35",
              linkName: "jade-plant",
              imageURL: "https://petplants.in/static/media/Jade_Round_White_Top_1.91b60ead.jpeg",
              type: "solo"
            },
            productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
            productDetails: {
              name: { label: "Name", value: 'Jade Plant' },
              type: { label: "Type", value: 'Succulent ' },
              // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
              location: { label: "Suitable Location", value: 'Indoors / Partial Outdoors' },
          
              plantHeight: { label: "Plant Height", value:  "5-10 inch (Approx.)" },
              plantWidth: { label: "Plant Width", value:  "4-8 inch (Approx.)" },
          
              // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
              // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
              planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
              // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
              // plantHeight: {label: "Plant Height", value: "10-20 cms"},
              containerType: { label: "Container Type", value: "Plastic" },
            },
            manufacturerDetails: {
              // name: {label: "Manufacturer", value: "Pet Plants"},
              // type: {label: "Country of Origin", value: "India"},
              quantity: { label: "Net Quantity", value: "1" },
              // boxHeight: {label: "Plant Weight", value: "250 Grams"},
              // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
              replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
              sunlight: { label: "Sunlight Guide", value: "Shaded, Indirect" },
              watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
              delivery: { label: "Delivery", value: "2-6 working days" },
              // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
              // boxBreadth:{label: "Box Breadth", value: "9 cms"},
              // plantHeight: {label: "Plant Height", value: "10-20 cms"},
              // containerType: {label: "Container Type", value: "Pet Bottle"},
            }
          }
},
{
    "product": "money-plant",
    "money-plant":{
        productData: {
          productName:  "Money Plant",
          productId:  "1001",
          productPrice: "195",
          productPriceBeforeDiscount: "299",
          productDiscountPercentage: "35",
          linkName: "money-plant",
          imageURL: "https://petplants.in/static/media/MP_Round_White_Top_1.3cd1d78d.jpeg",
          type: "solo"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Money Plant"  },
          type: { label: "Type", value:  "Pothos" },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
      
          plantHeight: { label: "Plant Height", value:  '8-15 inch' },
          plantWidth: { label: "Plant Width", value:  '5-10 inch' },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "1" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Shaded, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
},

{
  "product": "spider-plant",
  "spider-plant":{
      productData: {
        productName:  "Spider Plant",
        productId:  "1006",
        productPrice: "195",
        productPriceBeforeDiscount: "299",
        productDiscountPercentage: "35",
        linkName: "spider-plant",
        imageURL: "https://petplants.in/static/media/Spider_Square_White_Top_1.c1efdb6f.jpeg",
        type: "solo"
      },
      productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
      productDetails: {
        name: { label: "Name", value:  "Spider Plant"  },
        type: { label: "Type", value:  "Chlorophytum Comosum" },
        // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
        location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
    
        plantHeight: { label: "Plant Height", value:  '5-12 inch' },
        plantWidth: { label: "Plant Width", value:  '6-12 inch' },
    
        // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
        // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
        planterWeight: { label: "Planter Weight", value: "400 Grams (Approx.)" },
        // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        containerType: { label: "Container Type", value: "Plastic" },
      },
      manufacturerDetails: {
        // name: {label: "Manufacturer", value: "Pet Plants"},
        // type: {label: "Country of Origin", value: "India"},
        quantity: { label: "Net Quantity", value: "1" },
        // boxHeight: {label: "Plant Weight", value: "250 Grams"},
        // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
        replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
        sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
        watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
        delivery: { label: "Delivery", value: "2-6 working days" },
        // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
        // boxBreadth:{label: "Box Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        // containerType: {label: "Container Type", value: "Pet Bottle"},
      }
    }
}, // 99 Store Starts
{  // 00 Spider Plant
  "product": "99-spider-plant",
  "99-spider-plant":{
      productData: {
        productName:  "Spider Plant",
        productId:  "9901",
        productPrice: "99",
        productPriceBeforeDiscount: "149",
        productDiscountPercentage: "33",
        linkName: "99-spider-plant",
        imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/99_store/99_spider_plant/99_Spider_Plant_Front_1.jpg",
        type: "99-store"
      },
      productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
      productDetails: {
        name: { label: "Name", value:  "Spider Plant"  },
        type: { label: "Type", value:  "Chlorophytum Comosum" },
        // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
        location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
    
        plantHeight: { label: "Plant Height", value:  '3-6 inch' },
        plantWidth: { label: "Plant Width", value:  '4-9 inch' },
    
        // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
        // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
        planterWeight: { label: "Planter Weight", value: "300 Grams (Approx.)" },
        // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        containerType: { label: "Container Type", value: "Plastic" },
      },
      manufacturerDetails: {
        // name: {label: "Manufacturer", value: "Pet Plants"},
        // type: {label: "Country of Origin", value: "India"},
        quantity: { label: "Net Quantity", value: "1" },
        // boxHeight: {label: "Plant Weight", value: "250 Grams"},
        // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
        replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
        sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
        watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
        delivery: { label: "Delivery", value: "2-6 working days" },
        // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
        // boxBreadth:{label: "Box Breadth", value: "9 cms"},
        // plantHeight: {label: "Plant Height", value: "10-20 cms"},
        // containerType: {label: "Container Type", value: "Pet Bottle"},
      }
    }
  },
  {
    "product": "99-golden-money-plant",
    "99-golden-money-plant":{
        productData: {
          productName:  "Golden Money Plant",
          productId:  "9902",
          productPrice: "99",
          productPriceBeforeDiscount: "149",
          productDiscountPercentage: "33",
          linkName: "99-golden-money-plant",
          imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/99_store/99_golden_money_plant/99_Golden_Money_Plant_Front_1.jpg",
          type: "99-store"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Golden Money Plant"  },
          type: { label: "Type", value:  "Pothos" },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
      
          plantHeight: { label: "Plant Height", value:  '3-6 inch' },
          plantWidth: { label: "Plant Width", value:  '3-6 inch' },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "300 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "1" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
  },
  {
    "product": "99-jade-plant",
    "99-jade-plant":{
        productData: {
          productName:  "Jade Plant",
          productId:  "9903",
          productPrice: "99",
          productPriceBeforeDiscount: "149",
          productDiscountPercentage: "33",
          linkName: "99-jade-plant",
          imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/99_store/99_jade_plant/99_Jade_Plant_Front_2.jpg",
          type: "99-store"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Jade Plant"  },
          type: { label: "Type", value:  "Succulent" },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
      
          plantHeight: { label: "Plant Height", value:  '2-6 inch' },
          plantWidth: { label: "Plant Width", value:  '2-6 inch' },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "300 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "1" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
  },
  {
    "product": "99-green-queen-money-plant",
    "99-green-queen-money-plant":{
        productData: {
          productName:  "Green Queen Money Plant",
          productId:  "9904",
          productPrice: "99",
          productPriceBeforeDiscount: "149",
          productDiscountPercentage: "33",
          linkName: "99-green-queen-money-plant",
          imageURL: "https://s3.ap-south-1.amazonaws.com/images.petplants.in/99_store/99_green_queen_money_plant/99_Green_Queen_Money_Plant_Front_1.jpg",
          type: "99-store"
        },
        productFeatures: ["Quality Pot Planter", "Bottom drainage holes", "Added compost layer for growth"],
        productDetails: {
          name: { label: "Name", value:  "Green Queen Money Plant"  },
          type: { label: "Type", value:  "Pothos" },
          // botanicName: {label: "Botanic Name", value: "Epipremnum Aureum"},
          location: { label: "Suitable Location", value:  "Indoors / Outdoors" },
      
          plantHeight: { label: "Plant Height", value:  '3-6 inch' },
          plantWidth: { label: "Plant Width", value:  '3-6 inch' },
      
          // planterHeight: { label: "Planter Height", value: potShape == 'round' ? '4 inch' : "3.75 inch" },
          // planterWidth: { label: "Planter Width", value: potShape == 'round' ? '4.5 inch' : "4 inch" },
          planterWeight: { label: "Planter Weight", value: "300 Grams (Approx.)" },
          // boxBreadth:{label: "Planter Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          containerType: { label: "Container Type", value: "Plastic" },
        },
        manufacturerDetails: {
          // name: {label: "Manufacturer", value: "Pet Plants"},
          // type: {label: "Country of Origin", value: "India"},
          quantity: { label: "Net Quantity", value: "1" },
          // boxHeight: {label: "Plant Weight", value: "250 Grams"},
          // boxPacking: { label: "Packing (LxWxH)", value: "12x12x30 centimeters" },
          replacement: { label: "Replacement", value: "Upto 15 days from delivery" },
          sunlight: { label: "Sunlight Guide", value: "Direct, Indirect" },
          watering: { label: "Watering Guide", value: "Once a week, Avoid over watering" },
          delivery: { label: "Delivery", value: "2-6 working days" },
          // sunlight: {label: "Fertility Guide", value: "Add compost every quarter"},
          // boxBreadth:{label: "Box Breadth", value: "9 cms"},
          // plantHeight: {label: "Plant Height", value: "10-20 cms"},
          // containerType: {label: "Container Type", value: "Pet Bottle"},
        }
      }
  }
]

export default productList;