import React, { useEffect, useState } from "react";
import { isMobile, isDesktop } from "react-device-detect";
import { Link, useParams, Outlet, json, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  // const { productLinkData } = location.state;
  const [onProduct, setOnProduct] = useState(false);

  useEffect(() => {
    console.log("Footer Location: ", location);
  }, []);

  useEffect(() => {
    console.log("Footer Location 1: ", location);
    console.log("Footer Location 2: ", location.pathname.split("/")[1]);
    let isProductPage = location.pathname.split("/")[1] == 'plant';
    setOnProduct(isProductPage)
  }, [location]);

  return (
    <>
      {/* <div className="container-fluid d-none d-md-block"> */}
      <div className={`container-fluid ${
            isMobile && onProduct ? "mb-5" : "mb-md-0"
          }`}>
        <div className="row ">
          <div className="col-12 text-center bgGreen txtWhite px-3 py-2">
            @Copyrights - PetPlants {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
