import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import { isMobile } from "react-device-detect";

const BuyNowModal = () => {
  // let image1 = require("../../images/1080_pet_plant.jpg");
  const [cartProgress, setCartProgress] = useState(0);
  const [orderid, setOrderId] = useState("");
  const [qtyCount, setQtyCount] = useState(1);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [cartTotal, setCartTotal] = useState(99);
  const [discountAmount, setDiscountAmount] = useState(
    cartTotal * (discountPercent / 100)
  );
  const [finalCartTotal, setFinalCartTotal] = useState(
    cartTotal - (discountPercent * 100) / 100
  );
  const [orderState, setOrderState] = useState("InCart");
  // const [orderState, setOrderState] = useState("confirmOTP");
  const [address, setAddress] = useState({
    addLine1: "",
    addLine2: "",
    landmark: "",
    state: "MH",
    city: "Mumbai",
    pincode: "",
  });
  const [mobile, setMobile] = useState(0);
  const [otp, setOTP] = useState(1);
  const [pincodeValid, setPincodeValid] = useState(false);
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [addressFormValid, setAddressFormValid] = useState(true);
  const [addressFormSubmitClicked, setAddressFormSubmitClicked] =
    useState(false);

  const [otpText, setOtpText] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    console.log("cartTotal :: ", cartTotal);

    // console.log("discount Value :: ", cartTotal * (discountPercent/100));
    console.log("discountAmount :: ", discountAmount);
    // setFinalCartTotal(cartTotal - discountAmount);
    setFinalCartTotal(
      Math.floor(cartTotal - cartTotal * (discountPercent / 100))
    );
    console.log("finalCartTotal", finalCartTotal);
  }, [cartTotal]);

  const handleIncrement = () => {
    if (qtyCount < 1000) {
      setQtyCount(qtyCount + 1);
      setCartTotal(cartTotal + 99);
      setDiscountAmount(cartTotal * (discountPercent / 100));
      // console.log("cartTotal :: ", cartTotal);
      // setFinalCartTotal(cartTotal - (cartTotal * (discountPercent/100)));
      // console.log("finalCartTotal :: ", finalCartTotal);
    }
  };

  const handleDecrement = () => {
    if (qtyCount >= 2) {
      setQtyCount(qtyCount - 1);
      setCartTotal(cartTotal - 99);
      setDiscountAmount(cartTotal * (discountPercent / 100));
      // console.log("cartTotal :: ", cartTotal);
      // setFinalCartTotal(cartTotal - (cartTotal * (discountPercent/100)));
      // console.log("finalCartTotal :: ", finalCartTotal);
    }
  };

  const handleAddressInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setAddress({
      ...address,
      [name]: value,
    });
    // console.log(address);
    // if (name == "pincode" && (!address.pincode.trim().startsWith("4") || address.pincode.trim().length > 6) )
    //   setPincodeValid(false);
    // else setPincodeValid(true);
  };

  useEffect(() => {
    if (
      !address.pincode.trim().startsWith("4") ||
      address.pincode.trim().length != 6
    )
      setPincodeValid(false);
    else setPincodeValid(true);
  }, [address]);

  const handleMobileInputChange = (event) => {
    const value = event.target.value;
    console.log("Mobile Valid :: ", ["7", "8", "9"].includes(value.charAt(0)));

    // if(!["7", "8", "9"].includes(value.charAt(0))){
    //   setMobileNumberValid(false);
    //   return;
    // }
    // setMobileNumberValid(true);
    setMobile(value);
    // console.log(mobile);
  };

  const handleOTPInputChange = (event) => {
    const value = event.target.value;
    setOTP(value);
    // console.log(otp);
  };

  const handleOrderState = (state) => {
    if (state == "InCart") {
      setQtyCount(1);
      setCartTotal(99);
      setMobile(0);
      setOTP(0);
      setAddress({
        addLine1: "",
        addLine2: "",
        landmark: "",
        state: "MH",
        city: "Mumbai",
        pincode: "",
      });
      setAddressFormValid(true);
      setOrderState(state);
      setCartProgress(0);
      setAddressFormSubmitClicked(false);
    }

    if (state == "addressStage") {
      setOrderState(state);
      setCartProgress(25);
      setAddressFormSubmitClicked(false);
    }

    if (state == "cartDone") {
      setAddressFormSubmitClicked(true);
      let d1 = dayjs().millisecond();
      console.log("Date", d1);
      if (qtyCount == 0) {
        console.log("Please add quantity");
        return;
      }
      if (
        address.addLine1.trim() == "" ||
        address.addLine2.trim() == "" ||
        address.landmark.trim() == "" ||
        address.city == "" ||
        address.pincode.trim() == "" ||
        address.pincode.trim().startsWith("4") != true ||
        address.pincode.trim().length != 6
      ) {
        setAddressFormValid(false);
        console.log("Invalid Address Form");
        return;
      }
      setAddressFormValid(true);

      // setShowLoader(true);
      // setTimeout(() => {
      //   setShowLoader(false);
      // }, 2000);

      var data = {
        orderid: d1,
        quantity: qtyCount,
        amount: finalCartTotal,
        address_line_1: address.addLine1,
        address_line_2: address.addLine2,
        landmark: address.landmark,
        state: address.state,
        city: address.city,
        pincode: address.pincode,
      };

      // console.log("Order Details:");
      // console.log(data);

      // setOrderState(state);
      // setCartProgress(50);
      // return;

      setShowLoader(true);
      console.time("createOrder");
      // Save Order & Address API call
      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/createOrder",
          data
        )
        .then((response) => {
          console.timeEnd("createOrder");
          console.log("Order & Address Saved !!");
          console.log(response);
          if (response.data.orderid) setOrderId(response.data.orderid);
          setOrderState(state);
          setCartProgress(50);
          setAddressFormSubmitClicked(false);
          console.log(orderState);
          // setPost(response.data);
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    }

    if (state == "sendOTP") {
      console.log(mobile);
      console.log(mobile.toString().length);
      if (mobile.toString().length != 10) {
        console.log("Please add a valid mobile number.");
        setMobileNumberValid(false);
        return;
      }

      if (!["7", "8", "9"].includes(mobile.charAt(0))) {
        setMobileNumberValid(false);
        return;
      }

      setMobileNumberValid(true);

      // setShowLoader(true);
      // setTimeout(() => {
      //   setShowLoader(false);
      // }, 2000);

      // setOrderState(state);
      // setCartProgress(75);
      // return;

      setShowLoader(true);
      // Send OTP API call
      console.time("sendotp");

      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/sendotp",
          {
            orderid: orderid,
            mobile: mobile,
          }
        )
        .then((response) => {
          console.timeEnd("sendotp");
          console.log("OTP Sent !!");
          console.log(response.data.message);
          console.log(response);
          setOtpText(response.data.message);
          setOrderState(state);
          setCartProgress(75);
          console.log(orderState);
          setShowLoader(false);
          //  if(response.data.orderid) setOrderId(response.data.orderid);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    }

    if (state == "confirmOTP") {
      console.time("confirmotp");
      console.log(otp);
      console.log(otp.toString().length);
      if (otp.toString().length != 6) {
        console.log("Please add a valid otp.");
        return;
      }

      // setOrderState(state);
      // setCartProgress(100);
      // return;

      // Confirm OTP API call
      setShowLoader(true);
      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/confirmotp",
          {
            orderid: orderid,
            mobile: mobile,
            otp: otp,
          }
        )
        .then((response) => {
          console.timeEnd("confirmotp");
          console.log("Order Confirmed !!");
          console.log(response.data.message);
          setConfirmText(response.data.message);
          console.log(response);
          setOrderState(state);
          setCartProgress(100);
          console.log(orderState);
          setShowLoader(false);
          sendOrderAlert();
          //  if(response.data.orderid) setOrderId(response.data.orderid);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.data?.message)
            setOtpText(err.response.data.message);
          setShowLoader(false);
          // setConfirmText(err.response.data.message);
        });
    }
  };

  const sendOrderAlert = () => {
    console.time("orderalert");
    axios
      .get(
        "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/orderalert"
      )
      .then((response) => {
        console.timeEnd("orderalert");
        console.log("Order Alert Sent !!");
        console.log(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="modal" id="myModal">
      {/* <div className="modal-dialog">
              <div className="modal-content"> */}

      <div
        className={`modal-dialog row ${
          isMobile ? "modal-dialog-centered" : ""
        }`}
      >
        <div
          className={`modal-content col-12 offset-md-3 col-md-6 d-flex flex-column ${
            isMobile ? "justify-content-center" : ""
          }`}
        >
        
          {/* Loader */}
          {showLoader ? (
            <div className="loader_container">
              <div className="loader_text">Planting</div>
              <br />
              <div class="lds-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>

              {/* <div>Loading...</div>     */}
            </div>
          ) : null}
          {/* Loader */}

          <div className="modal-header">
            {orderState === "addressStage" ? (
              <span>
                <i
                  class="fa fa-arrow-left fa-2x cursor-pointer"
                  onClick={() => {
                    setOrderState("InCart");
                    setCartProgress(0);
                  }}
                ></i>
              </span>
            ) : null}
            {orderState === "cartDone" ? (
              <span>
                <i
                  class="fa fa-arrow-left fa-2x cursor-pointer"
                  onClick={() => {
                    setOrderState("addressStage");
                    setCartProgress(25);
                  }}
                ></i>
              </span>
            ) : null}
            {orderState === "sendOTP" ? (
              <span>
                <i
                  class="fa fa-arrow-left fa-2x cursor-pointer"
                  onClick={() => {
                    setOrderState("cartDone");
                    setCartProgress(50);
                  }}
                ></i>
              </span>
            ) : null}
            {/* { orderState === "confirmOTP" ? <span><i class="fa fa-arrow-left fa-2x cursor-pointer" onClick={() => { setOrderState("sendOTP"); setCartProgress(75); } }></i></span> : null }   */}

            <h4 className="modal-title margin-left">Cart </h4>

            {/* <h4 className="modal-title">Order As Guest</h4> */}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
            ></button>
          </div>

          <div className="modal-body">
            {/* Progress Bar */}
            <div
              className="progress margin-bottom-18"
              style={{ height: "10px" }}
            >
              <div
                className="progress-bar bg-success"
                role="progressbar"
                aria-label="Success example"
                style={{ width: cartProgress + "%" }}
                aria-valuenow={cartProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            {/* Progress Bar */}

            {/* Show Cart Details - Step #1 Start */}
            {orderState === "InCart" ? (
              <div>
                <div className="row cart_quantity">
                  <div className="col-4">Pet Plant</div>
                  <div className="col-4 text-center">
                    <span
                      class="badge bg-primary cursor-pointer"
                      onClick={() => handleDecrement()}
                    >
                      <strong>-</strong>
                    </span>
                    <span className="mx-2 user-select-none">{qtyCount}</span>
                    <span
                      class="badge bg-primary cursor-pointer"
                      onClick={() => handleIncrement()}
                    >
                      <strong>+</strong>
                    </span>
                  </div>
                  <div className="col-4 text-right">Rs. {cartTotal}</div>
                </div>

                {/* <div className="row cart_quantity margin-top-18">
                        <div className="col-4">Discount  </div>
                        <div className="col-4 text-center">{discountPercent}%  </div>
                        
                        <div className="col-4 text-right">Rs. { Math.ceil(cartTotal / 2) }</div>
                        
                      </div> */}
                <div className="row cart_quantity margin-top-18">
                  <div className="col-8">Shipping</div>

                  <div className="col-4 text-right">Rs. 0</div>
                </div>
                <hr></hr>
                <div className="row cart_quantity cartTotal fw-600">
                  <div className="col-8">Cart Total</div>

                  <div className="col-4 text-right">Rs. {finalCartTotal}</div>
                </div>
              </div>
            ) : null}

            {/* Address Form Start */}
            {orderState === "addressStage" ? (
              <div className="row mt-5">
                <div class="col-12 mt-3">
                  <label for="addLine1" class="form-label">
                    Address Line 1*:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="addLine1"
                    placeholder=""
                    name="addLine1"
                    value={address.addLine1}
                    onChange={(e) => handleAddressInputChange(e)}
                  />
                </div>

                <div class="col-12 mt-3">
                  <label for="addLine2" class="form-label">
                    Address Line 2*:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="addLine2"
                    placeholder=""
                    name="addLine2"
                    value={address.addLine2}
                    onChange={(e) => handleAddressInputChange(e)}
                  />
                </div>

                <div class="col-12 mt-3">
                  <label for="landmark" class="form-label">
                    Landmark*:
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="landmark"
                    placeholder=""
                    name="landmark"
                    value={address.landmark}
                    onChange={(e) => handleAddressInputChange(e)}
                  />
                </div>

                <div class="col-7 mt-3">
                  <label for="city" class="form-label">
                    City*:
                  </label>
                  <select
                    class="form-select"
                    name="city"
                    value={address.city}
                    onChange={(e) => handleAddressInputChange(e)}
                  >
                    <option>Mumbai</option>
                    <option>Navi Mumbai</option>
                    <option>Thane</option>
                  </select>
                </div>

                <div class="col-5 mt-3">
                  <label for="state" class="form-label">
                    State:
                  </label>
                  <br />
                  MH
                </div>

                <div class="col-12 mt-3">
                  <label for="pincode" class="form-label">
                    Pincode*:
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    id="pincode"
                    placeholder=""
                    name="pincode"
                    max="999999"
                    maxLength="6"
                    value={address.pincode}
                    onChange={(e) => handleAddressInputChange(e)}
                  />
                  {!pincodeValid && addressFormSubmitClicked ? (
                    <div class="col-12 mt-0">
                      <label for="InvalidForm" class="txtS9 form-label txtRed">
                        Please enter a valid pincode.
                      </label>
                    </div>
                  ) : null}
                </div>

                {!addressFormValid ? (
                  <div class="col-12 mt-3">
                    <label for="InvalidForm" class="form-label txtRed">
                      Please fill in the required(*) fields.
                    </label>
                  </div>
                ) : null}

                {/* Address Form End */}
              </div>
            ) : null}

            {/* Confirm Mobile - Step #3 Start */}
            {orderState === "cartDone" ? (
              <form>
                <div className="mb-3">
                  <label for="number" class="form-label">
                    Mobile: {mobileNumberValid}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="number"
                    placeholder="Enter Mobile Number"
                    name="number"
                    maxLength="10"
                    onChange={(e) => handleMobileInputChange(e)}
                  />
                  {!mobileNumberValid ? (
                    <div class="col-12 mt-0">
                      <label for="InvalidForm" class="txtS9 form-label txtRed">
                        Please enter a valid mobile number. {mobileNumberValid}
                      </label>
                    </div>
                  ) : null}
                </div>
              </form>
            ) : null}

            {orderState === "sendOTP" ? (
              <form>
                <div className="mb-3">
                  <label for="number" class="form-label">
                    OTP:
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="number"
                    placeholder="Enter OTP"
                    name="number"
                    maxLength="6"
                    onChange={(e) => handleOTPInputChange(e)}
                  />
                  <p className="">{otpText}</p>
                </div>
              </form>
            ) : null}

            {orderState === "confirmOTP" ? (
              <div>
                <h3 className="text-center">Order Confirmed !</h3>
                <h6 className="text-center mt-5">{confirmText}</h6>
                <h6 className="text-center mt-5">Cash On Delivery</h6>
              </div>
            ) : null}
          </div>

          <div className="modal-footer">
            {/* {orderState} */}

            {orderState === "InCart" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleOrderState("addressStage")}
              >
                Add Address
              </button>
            ) : null}

            {orderState === "addressStage" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleOrderState("cartDone")}
              >
                Confirm Order
              </button>
            ) : null}

            {orderState === "cartDone" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleOrderState("sendOTP")}
              >
                Send OTP
              </button>
            ) : null}

            {orderState === "sendOTP" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleOrderState("confirmOTP")}
              >
                Confirm OTP
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNowModal;
