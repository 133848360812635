import React from "react";
import { Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

const ProductCarouselItem = ({data}) => {
  // let circle = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/circle.png";
  // let square = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/square.png";

  let circle = require("../images/products/circle.png");
  let square = require("../images/products/square.png");
  // console.log(data);
  // console.log(typeof data.imageURL);
  // let image = require("../images/products/MP_Round_White_Top_1.jpeg");
  let image = !data.imageURL.includes("https") ? require(`../images/products/${data.imageURL}`) : data.imageURL;
  return (
    <>
     <Link className='no-decoration text-body' to={`/plant/${data.linkName}`} state={{ productLinkData: data }}>
      <div className={`my-3 pb-3 ${isMobile ? 'd-flex flex-column align-items-center' : ''}`}>
        {/* Carousel Item Start */}
        {/* <img src={image.default}  */}
        {/* <img src={(require(`../images/products/${data.imageURL}`)).default}  */}
        { 
          !data.imageURL.includes("https") 
          ? <img src={image.default} className="carousel_product_image"></img> 
          : <img src={image} className="carousel_product_image"></img>
        }
        
        {/* <div className="carousel_product_name mt-1 text-left">Some Plant</div>
        <div className="carousel_product_price text-left">Rs. 99/-</div> */}
        <div className="carousel_product_name mt-1">{data.productName}</div>

          <div className={`col-12 d-flex  align-items-center ${isMobile ? 'justify-content-center' : 'justify-content-start'}`}>
          <span className=" txtS9 ">Shapes:</span>
              <figure class={`figure d-flex justify-content-center align-items-center m-0 `} >
                <img
                  src={circle.default}
                  class="figure-img img-fluid rounded square-25"
                  alt="..."
                />
                {/* <figcaption class="figure-caption txtS9 border_top_lightgray">Round</figcaption> */}
              </figure>

              <figure class={`figure d-flex justify-content-center align-items-center m-0`} >
                <img
                  src={square.default}
                  class="figure-img img-fluid rounded square-25"
                  alt="..."
                />
                {/* <figcaption class="figure-caption txtS9 border_top_lightgray">Square</figcaption> */}
              </figure>
              {/* Pot Base Shape Selector(Circle | Square) */}

            </div>
            
            <div className={`col-12 d-flex align-items-center ${isMobile ? 'justify-content-center' : 'justify-content-start'}`}>
            <span className="txtS9">Colors:</span>
              {/* Pot Base Color Selector - Based on Base Shape (White | Pink | Magenta) */}
            <div className="d-flex justify-content-center align-items-center">
               <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotWhite borderLightgrey"  ></div>
                  {/* <span className="txtS9 mt-1">White</span> */}
              </div> 
              <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotPink borderLightgrey"></div>
              </div>
              <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotGreen borderLightgrey"></div>
              </div>
              <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotGrey borderLightgrey"></div>
              </div>
              <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotBlue borderLightgrey"></div>
              </div>
              <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotPurple borderLightgrey"></div>
              </div>

              {/* <div className={`d-flex flex-column justify-content-evenly align-items-center mx-1`} >
                  <div className="divCircle square-15 bgPotLimeGreen borderLightgrey" ></div>
              </div> */}
              </div>
           

            </div>

            <div className="price_container">
            <div className="product_price txtGreentxtBlack555 inline txtS12 me-2">
              <sup className="rupee">₹</sup>{data.productPrice}
            </div>
            {
              data.productPrice !== data.productPriceBeforeDiscount &&
            <div className="product_price_before_discount inline txtRed txtS9 me-2">
            <s><sup className="rupee">₹</sup>{data.productPriceBeforeDiscount}</s>
            </div>
            }
          </div>


        {/* Carousel Item End */}
      </div>
      </Link>
    </>
  );
};

export default ProductCarouselItem;
