import React from 'react';
import { isMobile } from 'react-device-detect';

const FacebookPosts = () => {
  return (
    <div className='facebbokposts_container mt-5'>
        {/* <hr></hr> */}
        <h2 className='text-center'>Follow PetPlants</h2>
        {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02ki8PwJx3z9MR5jCiowKZtzXxjy7MZdhsVdm5b4mYLVxpr8ZPW4hMddeUVQd6z5q2l%26id%3D109588115270972&show_text=true&width=500" width="100%" className='fb_post_iframe'  style={{ border: "none", overflow: "hidden", height: isMobile ? "510px" : "616px" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
        {/* <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D128978403347792%26id%3D100087068973650&show_text=true&width=500" width="100%" className='fb_post_iframe'  style={{ border: "none", overflow: "hidden", height: isMobile ? "510px" : "616px" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe> */}
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3D143167235262242%26id%3D100087068973650&show_text=true&width=500" width="100%" className='fb_post_iframe'  style={{ border: "none", overflow: "hidden", height: isMobile ? "510px" : "616px" }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        {/* <hr></hr> */}
    </div>
  )
}

export default FacebookPosts