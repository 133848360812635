import React, { useEffect, useRef, useLayoutEffect, lazy, Suspense } from 'react'; 
import './App.css';
import './utility/utility.css';
import { Route, Routes, Navigate, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';

import BuyNowModal from './components/BuyNow/BuyNowModal';
import Header from './components/Header';
import Footer from './components/Footer';
import BottomBuyNow from './components/BottomBuyNow';
import LinksComponent from './components/LinksComponent';
import DealsHeader from './components/Deals/DealsHeader';

const Product =  React.lazy(() => import('./components/Product'));
const PrivacyPolicy = React.lazy(() => import('./components/Policies/PrivacyPolicy'));
const RefundPolicy = React.lazy(() => import('./components/Policies/RefundPolicy'));
const ShippingPolicy = React.lazy(() => import('./components/Policies/ShippingPolicy'));
const Contact = React.lazy(() => import('./components/Contact'));
const CheckoutComponent = React.lazy(() => import('./components/CheckoutComponent'));
const TermsConditions = React.lazy(() => import('./components/Policies/TermsConditions'));
const ReceiptComponent = React.lazy(() => import('./components/Receipts/ReceiptComponent'));
const ReceiptInputComponent = React.lazy(() => import('./components/Receipts/ReceiptInputComponent'));
const BlogComponent = React.lazy(() => import('./components/BlogComponent'));
const BlogsComponent = React.lazy(() => import('./components/BlogsComponent'));
const WinterCareBlogComponent = React.lazy(() => import('./components/Blogs/WinterCareBlogComponent'))
const EasyToCare10HousePlantsForBeginners = React.lazy(() => import('./components/Blogs/EasyToCare10HousePlantsForBeginners'))
const BenefitsOfIndoorPlants = React.lazy(() => import('./components/Blogs/BenefitsOfIndoorPlants'));
const PlantsToKeepHomeCool = React.lazy(() => import('./components/Blogs/PlantsToKeepHomeCool'))

const PlantsComponent = React.lazy(() => import('./pages/plants'));



// import Product from './components/Product';
// import PrivacyPolicy from './components/Policies/PrivacyPolicy';
// import RefundPolicy from './components/Policies/RefundPolicy';
// import ShippingPolicy from './components/Policies/ShippingPolicy';
// import Contact from './components/Contact';
// import CheckoutComponent from './components/CheckoutComponent';
// import TermsConditions from './components/Policies/TermsConditions';
// import ReceiptComponent from './components/Receipts/ReceiptComponent';
// import ReceiptInputComponent from './components/Receipts/ReceiptInputComponent';


function App() {
  var vsettings = {
    dots: true,
    vertical: true,
    verticalSwiping: true,
    dotsClass: 'vertical-dots',
    accessibility: false,
    arrows: true
  };

  var hsettings = {
    dots: true,
    arrows: false,
    autoplay: false,
  };

  const slider = useRef(null);

function scroll(e){
    // if (slider === null)
    //     return 0;

    // e.wheelDelta > 0 ? (
    //   slider.current.slickPrev()
    // ) : (
    //   slider.current.slickNext()
    // );

};

useEffect(() => {
    window.addEventListener("wheel", scroll,true);

    return () => {
        window.removeEventListener("wheel", scroll, true);
    };
}, []);

const Wrapper = ({children}) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
} 

return (<>
<Wrapper>
<DealsHeader />

<Header />
<Routes>
{/* <Route path='/' element={<Navigate replace to='/home' />} /> */}
<Route path='/'  element={<Home />} />
{/* <Route path='/about' element={<About />} /> */}

<Route path='/plant/:productId' element={<React.Suspense fallback={<>...</>}><Product /></React.Suspense>} />
<Route path='/checkout' element={<React.Suspense fallback={<>...</>}><CheckoutComponent /></React.Suspense>} />
<Route path='/termsandconditions' element={<React.Suspense fallback={<>...</>}><TermsConditions /></React.Suspense>} />
<Route path='/privacypolicy' element={<React.Suspense fallback={<>...</>}><PrivacyPolicy /></React.Suspense>} />
<Route path='/refundpolicy' element={<React.Suspense fallback={<>...</>}><RefundPolicy /></React.Suspense>} />
<Route path='/shippingpolicy' element={<React.Suspense fallback={<>...</>}><ShippingPolicy /></React.Suspense>} />
<Route path='/contactus' element={<React.Suspense fallback={<>...</>}><Contact /></React.Suspense>} />
<Route path='/receipt' element={<React.Suspense fallback={<>...</>}><ReceiptInputComponent /></React.Suspense>} />
<Route path='/receipt/:orderId' element={<React.Suspense fallback={<>...</>}><ReceiptComponent /></React.Suspense>} />
<Route path='/blog/jade-care' element={<React.Suspense fallback={<>...</>}><BlogComponent /></React.Suspense>} />
<Route path='/blogs' element={<React.Suspense fallback={<>...</>}><BlogsComponent /></React.Suspense>} />
<Route path='/blog/jade-care' element={<React.Suspense fallback={<>...</>}><BlogComponent /></React.Suspense>} />
<Route path='/blog/winterplantcare' element={<React.Suspense fallback={<>...</>}><WinterCareBlogComponent /></React.Suspense>} />
<Route path='/blog/10easytocarehouseplantsforbeginners' element={<React.Suspense fallback={<>...</>}><EasyToCare10HousePlantsForBeginners /></React.Suspense>} />
<Route path='/blog/benefitsofindoorplants' element={<React.Suspense fallback={<>...</>}><BenefitsOfIndoorPlants /></React.Suspense>} />
<Route path='/blog/plantstokeephomecool' element={<React.Suspense fallback={<>...</>}><PlantsToKeepHomeCool /></React.Suspense>} />

<Route path='/plants' element={<React.Suspense fallback={<>...</>}><PlantsComponent /></React.Suspense>} />


</Routes>

<LinksComponent />
<Footer />
{/* <BottomBuyNow /> */}
{/* <BuyNowModal /> */}
</Wrapper>
</>);

}

export default App;
