import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  let logo = require("../images/logo.png");
  return (
    <>
      <div className="container-fluid p-0">
      {/* fixed-top */}
        <nav className="navbar navbar-expand-lg navbar-light bg-light py-0 bgGreen txtWhite text-md-left text-center d-flex justify-content-center justify-content-md-start">
        <Link
          className="navbar-brand no-decoration text-body px-2 txtWhite"
          to={`/`}
        >
          <img src={logo.default} alt="Pet Plants" className="header_logo" />
        </Link>

{/* <Link to={`/`}>
          <a className="navbar-brand ps-2 txtWhite" href="/">
        <img src={logo.default} alt="Pet Plants" className="header_logo" />

          </a>
          </Link>     */}


          {/* <button className="navbar-toggler borderWhite margin-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon "></span>
      <i className="fa fa-bars txtWhite" ></i>
    </button> */}
          <div className="  text-center fw-900 ">
            Pet Plants
          </div>
          {/* <button
            className="buy_button bgOrange my-4 px-5 d-none d-md-block"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
          >
            ORDER NOW
          </button> */}
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              {/* <a class="nav-link active" aria-current="page" href="#">Home</a> */}
              {/* <a className="nav-link ps-3" href="#features">Features</a>
        <a className="nav-link ps-3" href="#buynow">Buy Now</a>
        <a className="nav-link ps-3" href="#delivery">Delivery</a> */}
              {/* <a className="nav-link ps-3" href="#contact">Contact</a> */}
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
