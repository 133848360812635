import React, { useState } from 'react';
import { useCountdown } from '../../hooks/useCountdown';
import dayjs from "dayjs";

const DealsHeader = () => {
let targetDate = dayjs().set('M', 1).set('D', 29).set('h', 23).set('m', 59).set('s', 59);
const [days, hours, minutes, seconds] = useCountdown(targetDate);
    if (days + hours + minutes + seconds <= 0){
        return(<div className='bgOrange text-center w-100'>
        <p className='fs-5  m-0'>
            Limited Deal Expired !!
        </p>
    </div>)   
    } else {
    return (
    <div className='bgOrange text-center w-100'>
        <p className='fs-5  m-0'>
            Limited Rs.99/- Deal Ends in {days}d {hours}h {minutes}m {seconds}s
        </p>
    </div>
    )
    }
}

export default DealsHeader