import React from "react";

const Delivery = () => {
  return (
    <>
    <div className="container-fluid" id="delivery">
      <div className="row">
        <div className="col-12 text-center p-5 m-0">
          <h4>Free shipping within Mumbai, Navi Mumbai and Thane</h4>
        </div>
      </div>
      </div>
    </>
  );
};

export default Delivery;
