import React from 'react'
import Header from './Header';
import MainSlider from './MainSlider';
import Features from './Features';
import BuyNow from './BuyNow/BuyNow';
import Delivery from './Delivery';
import Testimonials from './Testimonials';
import Contact from './Contact';
import Footer from './Footer';
import Faqs from './Faqs';
import BottomBuyNow from './BottomBuyNow';
import FacebookPosts from './FacebookPosts';
import HarGharPetPlant from './HarGharPetPlant';
import ProductCarousel from './ProductCarousel';
import Install from './Install';
import CashOnDeliveryComponent from './CashOnDeliveryComponent';
import FreeDeliveryComponent from './FreeDeliveryComponent';
import Store99Carousel from './Store99Carousel';
import Winners from '../pages/winners';


const Home = () => {
  return (<>
    {/* <Header /> */}
    <MainSlider />
    {/* <Features /> */}
    <ProductCarousel title={'Featured Plants'}/>
    <FreeDeliveryComponent />
    <Store99Carousel title={'99 Store'}/>
    {/* <CashOnDeliveryComponent /> */}
    <BuyNow />
    <HarGharPetPlant />
    <FacebookPosts />
    <Delivery />
    {/* <Winners /> */}
    <Install />
    <Testimonials/>

    {/* <Faqs /> */}
    {/* <Contact /> */}
    {/* <Footer />
    <BottomBuyNow /> */}
    </>)
}

export default Home