import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import axios from "axios";
import BuyNowModal from "./BuyNowModal";
import { Link } from "react-router-dom";
import productList from '../utilities/ProductList';

const BuyNow = () => {
  let buyNowBtn = "";

  const uploadClick = () => {
    // e.preventDefault();
    buyNowBtn.click();
    return false;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const bn = queryParams.get("bn");
    console.log("bn :: ", bn);
    if (bn) uploadClick();

    // fetch('https://randomuser.me/api/')
    //   .then(results => results.json())
    //   .then(data => {
    //     const {name} = data.results[0];
    //     setFirstName(name.first);
    //     setLastName(name.last);
    //   });

 
  }, []); // <-- Have to pass in [] here!

  // let image1 = require("../images/Main-Image-Wide-3.jpg");
  // let image1 = require("../../images/1080_pet_plant.jpg");
  // let image1 = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/moneyplant/Round_White_Top_1.jpeg";
// let image1 = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/collections/jade_collection_1.jpeg";
  // let image1 = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_Pink_Top_2.jpeg";
  let image1 = "https://s3.ap-south-1.amazonaws.com/images.petplants.in/jade/Round_White_Top_1.jpeg";
  
  const [cartProgress, setCartProgress] = useState(0);
  const [orderid, setOrderId] = useState("");
  const [qtyCount, setQtyCount] = useState(1);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [cartTotal, setCartTotal] = useState(195);
  const [discountAmount, setDiscountAmount] = useState(cartTotal * (discountPercent/100));
  const [finalCartTotal, setFinalCartTotal] = useState(cartTotal - ((discountPercent*100)/100));
  const [orderState, setOrderState] = useState("InCart");
  // const [orderState, setOrderState] = useState("confirmOTP");
  const [address, setAddress] = useState({
    addLine1: "",
    addLine2: "",
    landmark: "",
    state: "MH",
    city: "Mumbai",
    pincode: "",
  });
  const [mobile, setMobile] = useState(0);
  const [otp, setOTP] = useState(1);
  const [pincodeValid, setPincodeValid] = useState(false);
  const [mobileNumberValid, setMobileNumberValid] = useState(true);
  const [addressFormValid, setAddressFormValid] = useState(true);
  const [addressFormSubmitClicked, setAddressFormSubmitClicked] = useState(false);

  const [otpText, setOtpText] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [productId, setProductId] = useState("jade-plant");
  const [product, setProduct] = useState({});
  const [products, setProducts] = useState(productList);
  
  useEffect(() => {
    for(var x in productList){
      if(productList[x].product == productId) {
        setProduct(productList[x][productId]);
        // product = productList[x][productId];
      }
    }
  }, [products])

  useEffect(() => {
    console.log("cartTotal :: ", cartTotal);
    
    // console.log("discount Value :: ", cartTotal * (discountPercent/100));
    console.log("discountAmount :: ", discountAmount);
    // setFinalCartTotal(cartTotal - discountAmount);
    setFinalCartTotal(Math.floor(cartTotal - (cartTotal * (discountPercent/100))));
    console.log('finalCartTotal', finalCartTotal);
  }, [cartTotal])

  const handleIncrement = () => {
    if (qtyCount < 1000) {
      setQtyCount(qtyCount + 1);
      setCartTotal(cartTotal + 199);
      setDiscountAmount(cartTotal * (discountPercent/100));
      // console.log("cartTotal :: ", cartTotal);
      // setFinalCartTotal(cartTotal - (cartTotal * (discountPercent/100)));
      // console.log("finalCartTotal :: ", finalCartTotal);
    }
  };


  
  const handleDecrement = () => {
    if (qtyCount >= 2) {
      setQtyCount(qtyCount - 1);
      setCartTotal(cartTotal - 199);
      setDiscountAmount(cartTotal * (discountPercent/100));
      // console.log("cartTotal :: ", cartTotal);
      // setFinalCartTotal(cartTotal - (cartTotal * (discountPercent/100)));
      // console.log("finalCartTotal :: ", finalCartTotal);
    }
  };

  const handleAddressInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setAddress({
      ...address,
      [name]: value,
    });
    // console.log(address);
    // if (name == "pincode" && (!address.pincode.trim().startsWith("4") || address.pincode.trim().length > 6) )
    //   setPincodeValid(false);
    // else setPincodeValid(true);
  };

  useEffect(() => {
    if (!address.pincode.trim().startsWith("4") || address.pincode.trim().length != 6 ) setPincodeValid(false);
    else setPincodeValid(true);
  }, [address]);

  const handleMobileInputChange = (event) => {
    const value = event.target.value;
    console.log("Mobile Valid :: ", ["7", "8", "9"].includes(value.charAt(0)));

    // if(!["7", "8", "9"].includes(value.charAt(0))){
    //   setMobileNumberValid(false);
    //   return;
    // }
    // setMobileNumberValid(true);
    setMobile(value);
    // console.log(mobile);
  };

  const handleOTPInputChange = (event) => {
    const value = event.target.value;
    setOTP(value);
    // console.log(otp);
  };

  const handleOrderState = (state) => {
    if (state == "InCart") {
      setQtyCount(1);
      setCartTotal(99);
      setMobile(0);
      setOTP(0);
      setAddress({
        addLine1: "",
        addLine2: "",
        landmark: "",
        state: "MH",
        city: "Mumbai",
        pincode: "",
      });
      setAddressFormValid(true);
      setOrderState(state);
      setCartProgress(0);
      setAddressFormSubmitClicked(false);
    }

    if (state == "addressStage") {
      setOrderState(state);
      setCartProgress(25);
      setAddressFormSubmitClicked(false);
    }

    if (state == "cartDone") {
      setAddressFormSubmitClicked(true);
      let d1 = dayjs().millisecond();
      console.log("Date", d1);
      if (qtyCount == 0) {
        console.log("Please add quantity");
        return;
      }
      if (
        address.addLine1.trim() == "" ||
        address.addLine2.trim() == "" ||
        address.landmark.trim() == "" ||
        address.city == "" ||
        address.pincode.trim() == "" ||
        address.pincode.trim().startsWith("4") != true ||
        address.pincode.trim().length != 6
      ) {
        setAddressFormValid(false);
        console.log("Invalid Address Form");
        return;
      }
      setAddressFormValid(true);
 

      // setShowLoader(true);
      // setTimeout(() => {
      //   setShowLoader(false);
      // }, 2000);

      var data = {
        orderid: d1,
        quantity: qtyCount,
        amount: finalCartTotal,
        address_line_1: address.addLine1,
        address_line_2: address.addLine2,
        landmark: address.landmark,
        state: address.state,
        city: address.city,
        pincode: address.pincode,
      }

      // console.log("Order Details:");
      // console.log(data);

      // setOrderState(state);
      // setCartProgress(50);
      // return;

      setShowLoader(true);
      console.time("createOrder");
      // Save Order & Address API call
      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/createOrder",
          data
        )
        .then((response) => {
          console.timeEnd("createOrder");
          console.log("Order & Address Saved !!");
          console.log(response);
          if (response.data.orderid) setOrderId(response.data.orderid);
          setOrderState(state);
          setCartProgress(50);
          setAddressFormSubmitClicked(false);
          console.log(orderState);
          // setPost(response.data);
          setShowLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    }

    if (state == "sendOTP") {
      console.log(mobile);
      console.log(mobile.toString().length);
      if (mobile.toString().length != 10) {
        console.log("Please add a valid mobile number.");
        setMobileNumberValid(false);
        return;
      }

      if (!["7", "8", "9"].includes(mobile.charAt(0))) {
        setMobileNumberValid(false);
        return;
      }

      setMobileNumberValid(true);

      // setShowLoader(true);
      // setTimeout(() => {
      //   setShowLoader(false);
      // }, 2000);

      // setOrderState(state);
      // setCartProgress(75);
      // return;

      setShowLoader(true);
      // Send OTP API call
      console.time("sendotp");
      
      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/sendotp",
          {
            orderid: orderid,
            mobile: mobile,
          }
        )
        .then((response) => {
          console.timeEnd("sendotp");
          console.log("OTP Sent !!");
          console.log(response.data.message);
          console.log(response);
          setOtpText(response.data.message);
          setOrderState(state);
          setCartProgress(75);
          console.log(orderState);
          setShowLoader(false);
          //  if(response.data.orderid) setOrderId(response.data.orderid);
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
        });
    }

    if (state == "confirmOTP") {
      console.time("confirmotp");
      console.log(otp);
      console.log(otp.toString().length);
      if (otp.toString().length != 6) {
        console.log("Please add a valid otp.");
        return;
      }

      // setOrderState(state);
      // setCartProgress(100);
      // return;

      // Confirm OTP API call
      setShowLoader(true);
      axios
        .post(
          "https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/confirmotp",
          {
            orderid: orderid,
            mobile: mobile,
            otp: otp,
          }
        )
        .then((response) => {
          console.timeEnd("confirmotp");
          console.log("Order Confirmed !!");
          console.log(response.data.message);
          setConfirmText(response.data.message);
          console.log(response);
          setOrderState(state);
          setCartProgress(100);
          console.log(orderState);
          setShowLoader(false);
          sendOrderAlert();
          //  if(response.data.orderid) setOrderId(response.data.orderid);
        })
        .catch((err) => {
          console.log(err);
          if(err?.response?.data?.message) setOtpText(err.response.data.message);
          setShowLoader(false);
          // setConfirmText(err.response.data.message);
        });
    }
  };

  const sendOrderAlert = () => {
    console.time("orderalert");
    axios
        .get("https://j6xlwhwbtd.execute-api.ap-south-1.amazonaws.com/test/orderalert")
        .then((response) => {
          console.timeEnd("orderalert");
          console.log("Order Alert Sent !!");
          console.log(response.data.message);
        })
        .catch((err) => {
          console.log(err);
        });
  }

  return (
    <>
      <div className="container-fluid border_top_white" id="buynow">
        <div className="row">
          <div className="col-md-6 p-0">
            <img src={image1} alt="Edges" className="feature_image" />
          </div>
          <div className="col-md-6 bgGreen flex-center p-0 px-5">
            {/* <h5 className="mt-5 text-center"></h5> */}
            <h1 className="mt-4 text-center txtWhite">GET YOUR JADE PLANT</h1>
            {/* <h3 className="mt-4 text-center txtWhite">New Year Sale</h3> */}
            {/* <h3 className="mt-4 text-center txtWhite">50% Off</h3> */}

            {/* <h1 className="mt-4 text-center txtWhite">GET YOUR MONEY PLANT</h1> */}
            {/* <h1 className="mt-4 text-center txtWhite">GET YOUR JADE COLLECTION (6 pcs)</h1> */}
            <h3 className="mt-5 txtWhite">
              {/* {" "} */}
              <strong> Rs.195/- </strong>
              <sup className="txtS12 text-decoration-line-through"><small>Rs.299</small></sup>
            </h3>
            {/* <h5 className="mt-4 text-center">
              Bring home the joy of watching your pet plant grow
            </h5> */}

<Link className="no-decoration" to={`/checkout`} state={{ productId, potShape: "round", potColor: "white", product }}>
                <button
                  class="buy_button bgOrange my-4 px-5"
                  type="button"
                >
                  Buy Now
                </button>
              </Link>
            {/* <button
              className="buy_button bgOrange my-4 px-5"
              data-bs-toggle="modal"
              data-bs-target="#myModal"
              ref={(input) => {
                buyNowBtn = input;
              }}
              onClick={() => handleOrderState("InCart")}
            >
              BUY NOW
            </button> */}
          </div>

          {/* <BuyNowModal /> */}
        </div>
      </div>
    </>
  );
};

export default BuyNow;
