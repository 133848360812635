import React from 'react'

const FreeDeliveryComponent = () => {
  return (
<div className='bgOrange p-4 text-center'>
        <h2 className='txtWhite'>
            Free Delivery< br/>
            Cash On Delivery
        </h2>
    </div>
  )
}

export default FreeDeliveryComponent