import React from "react";
import Slider from "react-slick";

const Testimonials = () => {
  var hsettings = {
    dots: true,
    arrows: false,
    autoplay: true,
    infinite: true,
    swipeToSlide: true,
    speed: 1000,
    autoplaySpeed: 7000,
    pauseOnHover: true
  };  
  return (<>
    <div className="container-fluid" id="home">
     <div className="row p-0 ">
       <div className="col-12 p-0">
        <h3 className="text-center bgGreen txtWhite padding-top-50 mb-0">TESTIMONIALS</h3>
         <Slider {...hsettings}>
           <div class=" flex-center py-4 bgGreen txtWhite">
             <h4 className="slider_main_text flex-center text-center px-4  min-height-200">
              "My work desk feels more positive with these pet plants on it."
             </h4>
             <p className="margin-top-27">Gaurav, Mumbai</p>
           </div>
           <div class=" flex-center py-4 bgGreen txtWhite">
             <h4 className="slider_main_text flex-center text-center px-4  min-height-200">
              "Pet plants have added greenary to our home, we have embraced them as part of our family."
             </h4>
             <p className="margin-top-27">Neha, Navi Mumbai</p>
           </div>
           <div class=" flex-center py-4 bgGreen txtWhite">
             <h4 className="slider_main_text flex-center text-center px-4 min-height-200">
              "I've named my pet plant as `LEAFY` and I take care of it as my personal pet now."
             </h4>
             <p className="margin-top-27">Vikas, Thane</p>
           </div>
         </Slider>
         </div>
         </div>
         </div>
       </>
       );
}

export default Testimonials