import React from 'react';

const HarGharPetPlant = () => {
  return (
    <div className='bgOrange p-4 text-center'>
        <h2 className='slogan txtWhite'>
            #HarGharPetPlant
        </h2>
    </div>
  )
}

export default HarGharPetPlant